import React from 'react';
import { Link, Frame, Words, Heading } from 'arwes';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
const Bandcamp = () => {
    return (
        <Layout>
            <Helmet>
                <title>glitchy.website BAndCaMP embedS--</title>
            </Helmet>
            <Frame animate level={1} corners={4} layer="primary" show>
                <div style={{ padding: "1rem" }}>
                    <Heading node="h1">
                        <Link href="/">glitchy.website &raquo;&nbsp;</Link>
                        <Words animate>
                            bandcamp
                        </Words>
                    </Heading>

                    <BandcampEmbed trackId={1159586074} />
                    <BandcampEmbed albumId={2262989140} />
                    <BandcampEmbed albumId={2480641060} />
                    <BandcampEmbed albumId={1635113546} />
                </div>
            </Frame>
        </Layout>
    );
};

interface BandcampEmbedProps {
    trackId?: number;
    albumId?: number;
}
const BandcampEmbed = ({ trackId, albumId }: BandcampEmbedProps) => {
    let src = '';
    if (albumId) {
        src = `https://bandcamp.com/EmbeddedPlayer/album=${albumId}/size=large/bgcol=130214/linkcol=B17CC8/tracklist=true/transparent=true/`;
    }
    if (trackId) {
        src = `https://bandcamp.com/EmbeddedPlayer/track=${trackId}/size=large/bgcol=130214/linkcol=B17CC8/tracklist=false/transparent=true/`;
    }
    return <iframe title={"Bandcamp " + (trackId || albumId)} style={{ display: "block", marginTop: "1rem", border: 0, width: 320, height: trackId ? 442 : 690, margin: "0 auto 2rem" }} src={src} seamless></iframe>;
};

export default Bandcamp;